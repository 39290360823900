<template>
  <router-view></router-view>
</template>

<script lang="js">
export default {
  name: 'Coming-Soon',
  data() {
    return {

    };
  },
};
</script>

<style></style>
